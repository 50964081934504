var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Comercialización")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Facturación")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),(
          _vm.$store.getters.can('com.liquidacion.edit') ||
          _vm.$store.getters.can('com.liquidacion.create')
        )?_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card card-secondary card-outline"},[_c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Liquidaciones")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cliente")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$v.form.arCliente.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"placeholder":"Clientes","label":"razon_social","options":_vm.listasForms.clientes,"disabled":_vm.form.arEstado.numeracion == 2 || _vm.bloquear},model:{value:(_vm.form.arCliente),callback:function ($$v) {_vm.$set(_vm.form, "arCliente", $$v)},expression:"form.arCliente"}})],1)]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_ini),expression:"form.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","id":"fecha_ini","disabled":_vm.accion == 'Editar' ||
                            _vm.form.arEstado.numeracion == 2},domProps:{"value":(_vm.form.fecha_ini)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_ini", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_fin),expression:"form.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","id":"fecha_fin","disabled":_vm.accion == 'Editar' ||
                            _vm.form.arEstado.numeracion == 2},domProps:{"value":(_vm.form.fecha_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_fin", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Estado")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$v.form.arEstado.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"placeholder":"Estados","label":"descripcion","options":_vm.listasForms.estados,"disabled":_vm.accion == 'Crear' || _vm.form.arEstado.numeracion == 2},on:{"input":function($event){return _vm.save()}},model:{value:(_vm.form.arEstado),callback:function ($$v) {_vm.$set(_vm.form, "arEstado", $$v)},expression:"form.arEstado"}})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Observaciones")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observaciones),expression:"form.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"disabled":_vm.form.arEstado.numeracion == 2},domProps:{"value":(_vm.form.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observaciones", $event.target.value)}}})])])])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.arEstado.numeracion != 2 && !_vm.$v.form.$invalid),expression:"form.arEstado.numeracion != 2 && !$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.validarRango()}}},[_c('i',{class:_vm.accion == 'Editar'
                        ? 'fas fa-sync-alt'
                        : 'fas fa-paper-plane'}),_c('br'),_vm._v(" "+_vm._s(_vm.accion == "Editar" ? "Refrescar" : "Guardar")+" ")]),_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])]),(_vm.viajesGrupo[0])?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card card card-secondary card-outline col-md-12"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"col-12 pt-2 pb-2"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"btn-group float-right"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.arEstado.numeracion != 2),expression:"form.arEstado.numeracion != 2"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.refrescarDetFac}},[_c('i',{staticClass:"fas fa-sync-alt"})])])])])])]),_c('div',{staticClass:"card-body"},[_vm._m(2),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},_vm._l((_vm.viajesGrupo),function(viajes,index){return _c('li',{key:viajes.id,staticClass:"nav-item"},[_c('a',{class:index == 0 ? 'nav-link active' : 'nav-link',attrs:{"data-toggle":"tab","href":'#Id-' + index},on:{"click":function($event){_vm.cambioPestaña(index)}}},[_vm._v(_vm._s(viajes[index].sitio.nombre))])])}),0),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{class:_vm.posicion == 0
                      ? 'tab-pane fade active show'
                      : 'tab-pane fade',attrs:{"id":'Id-' + _vm.posicion}},[_c('div',{staticClass:"card col-md-12 card-navy card-outline mt-4"},[_c('div',{staticClass:"card-body",staticStyle:{"display":"block"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-hover table-sm text-center",attrs:{"id":"docs"}},[_vm._m(3),_c('tbody',_vm._l((_vm.viajesGrupo[_vm.posicion]),function(viaje){return _c('tr',{key:viaje.id},[_c('td',[_c('button',{staticClass:"btn btn-sm btn-default py-0",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-detViaje"},on:{"click":function($event){return _vm.llenarModalDetViaje(
                                        viaje.det_guia_tercero_id,
                                        viaje.guia.tipo_operacion
                                      )}}},[_vm._v(" "+_vm._s(viaje.det_guia_tercero_id)+" ")])]),_c('td',[_vm._v(" "+_vm._s(viaje.det_guia_tercero.fecha_cargue)+" ")]),_c('td',[_vm._v(_vm._s(viaje.guia.numero)+" - "+_vm._s(viaje.guia.digito_verificacion))]),_c('td',[_vm._v(" "+_vm._s(viaje.precio_brent)+" ")]),_c('td',[_vm._v(" "+_vm._s(viaje.precio_ofertado)+" ")]),_c('td',[_vm._v(" "+_vm._s(viaje.nsv)+" ")]),_c('td',[_vm._v(" "+_vm._s(viaje.total)+" ")])])}),0)])])])])])])])])])]):_vm._e()]):_vm._e(),_c('ViajesDetViaje',{ref:"ViajesDetViaje"})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Liquidación")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Detalle Facturación")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Viaje")]),_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Guia")]),_c('th',[_vm._v("Brent Promedio")]),_c('th',[_vm._v("Precio Ofertado")]),_c('th',[_vm._v("Nsv")]),_c('th',[_vm._v("Total")])])])
}]

export { render, staticRenderFns }