<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Facturación</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section
          class="content"
          v-if="
            $store.getters.can('com.liquidacion.edit') ||
            $store.getters.can('com.liquidacion.create')
          "
        >
          <div class="container-fluid">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h5 class="mb-0">Formulario {{ accion }} Liquidaciones</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row justify-content-md-center">
                      <div class="col-md-5">
                        <div class="form-group">
                          <label>Cliente</label>
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="form.arCliente"
                            placeholder="Clientes"
                            label="razon_social"
                            :options="listasForms.clientes"
                            :class="
                              $v.form.arCliente.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              form.arEstado.numeracion == 2 || bloquear
                            "
                          >
                          </v-select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Fecha Inicial</label>
                          <input
                            type="date"
                            v-model="form.fecha_ini"
                            class="form-control form-control-sm"
                            id="fecha_ini"
                            :class="
                              $v.form.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              accion == 'Editar' ||
                              form.arEstado.numeracion == 2
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Fecha Final</label>
                          <input
                            type="date"
                            v-model="form.fecha_fin"
                            class="form-control form-control-sm"
                            id="fecha_fin"
                            :class="
                              $v.form.fecha_fin.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              accion == 'Editar' ||
                              form.arEstado.numeracion == 2
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Estado</label>
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="form.arEstado"
                            placeholder="Estados"
                            label="descripcion"
                            :options="listasForms.estados"
                            :class="
                              $v.form.arEstado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="save()"
                            :disabled="
                              accion == 'Crear' || form.arEstado.numeracion == 2
                            "
                          >
                          </v-select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Observaciones</label>
                          <textarea
                            v-model="form.observaciones"
                            class="form-control form-control-sm"
                            :disabled="form.arEstado.numeracion == 2"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    v-show="form.arEstado.numeracion != 2 && !$v.form.$invalid"
                    class="btn bg-primary col-md-1"
                    @click="validarRango()"
                  >
                    <i
                      :class="
                        accion == 'Editar'
                          ? 'fas fa-sync-alt'
                          : 'fas fa-paper-plane'
                      "
                    ></i
                    ><br />
                    {{ accion == "Editar" ? "Refrescar" : "Guardar" }}
                  </button>

                  <button @click="back()" class="btn bg-secondary col-md-1">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid" v-if="viajesGrupo[0]">
            <div class="card card card-secondary card-outline col-md-12">
              <div class="card-header pt-2 pb-2">
                <div class="col-12 pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-10">
                      <h5 class="mb-0">Detalle Facturación</h5>
                    </div>
                    <div class="col-md-2">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-primary"
                          v-show="form.arEstado.numeracion != 2"
                          @click="refrescarDetFac"
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    
                  </div>

                </div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    class="nav-item"
                    v-for="(viajes, index) in viajesGrupo"
                    :key="viajes.id"
                  >
                    <a
                      :class="index == 0 ? 'nav-link active' : 'nav-link'"
                      data-toggle="tab"
                      :href="'#Id-' + index"
                      @click="cambioPestaña(index)"
                      >{{ viajes[index].sitio.nombre }}</a
                    >
                  </li>
                </ul>
                
                <div class="tab-content" id="myTabContent">
                  <div
                    :class="
                      posicion == 0
                        ? 'tab-pane fade active show'
                        : 'tab-pane fade'
                    "
                    :id="'Id-' + posicion"
                  >
                    <div class="card col-md-12 card-navy card-outline mt-4">
                      <div class="card-body" style="display: block">
                        <div class="row">
                          <div class="col-md-12">
                            <table
                              id="docs"
                              class="table table-bordered table-hover table-sm text-center"
                            >
                              <thead>
                                <tr>
                                  <th>Viaje</th>
                                  <th>Fecha</th>
                                  <th>Guia</th>
                                  <th>Brent Promedio</th>
                                  <th>Precio Ofertado</th>
                                  <th>Nsv</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="viaje in viajesGrupo[posicion]"
                                  :key="viaje.id"
                                >
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-default py-0"
                                      data-toggle="modal"
                                      data-target="#modal-form-detViaje"
                                      @click="
                                        llenarModalDetViaje(
                                          viaje.det_guia_tercero_id,
                                          viaje.guia.tipo_operacion
                                        )
                                      "
                                    >
                                      {{ viaje.det_guia_tercero_id }}
                                    </button>
                                  </td>
                                  <td>
                                    {{ viaje.det_guia_tercero.fecha_cargue }}
                                  </td>
                                  <td>{{ viaje.guia.numero }} - {{ viaje.guia.digito_verificacion }}</td>
                                  <td>
                                    {{ viaje.precio_brent }}
                                  </td>
                                  <td>
                                    {{ viaje.precio_ofertado }}
                                  </td>
                                  <td>
                                    {{ viaje.nsv }}
                                  </td>
                                  <td>
                                    {{ viaje.total }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
        <ViajesDetViaje ref="ViajesDetViaje" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import ViajesDetViaje from "../viajes/ViajesDetViaje";

export default {
  name: "FacturacionForm",
  components: {
    Loading,
    vSelect,
    ViajesDetViaje,
  },
  data() {
    return {
      cargando: false,
      bloquear: false,
      posicion: 0,
      accion: null,
      viajesGrupo: [],
      form: {
        id: null,
        arEstado: [],
        arCliente: [],
        fecha_ini: null,
        fecha_fin: null,
        observaciones: null,
      },
      listasForms: {
        detFacturacion: [],
        clientes: [],
        estados: [],
      },
    };
  },
  validations() {
    return {
      form: {
        arCliente: { required },
        arEstado: { required },
        fecha_ini: { required },
        fecha_fin: { required },
      },
    };
  },

  methods: {
    async init() {
      await this.getClientes();
      await this.getEstados();
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = {
          id: await this.$route.params.data_edit.id,
          estado: await this.$route.params.data_edit.estado,
          cliente_id: await this.$route.params.data_edit.cliente_id,
          fecha_fin: await this.$route.params.data_edit.fecha_fin,
          fecha_ini: await this.$route.params.data_edit.fecha_ini,
          observaciones: await this.$route.params.data_edit.observaciones,
          arEstado: this.listasForms.estados.find(
            (estado) =>
              estado.numeracion === this.$route.params.data_edit.estado
          ),
          arCliente: this.listasForms.clientes.find(
            (cliente) => cliente.id === this.$route.params.data_edit.cliente_id
          ),
        };
        if (this.form.id) {
          this.bloquear = true;
        }
        this.metodo = "PUT";
        await axios
          .get("/api/com/detGuiaRegalias", {
            params: {
              id: this.form.id,
              accion: "Editar",
              cliente_id: this.form.cliente_id,
              fecha_fin: this.form.fecha_fin,
              fecha_ini: this.form.fecha_ini,
            },
          })
          .then((response) => {
            this.listasForms.detFacturacion = response.data;
          });

        await this.agruparViajes();
      } else {
        this.form = {
          arEstado: this.listasForms.estados.find(
            (estado) => estado.numeracion == 1
          ),
        };
        this.metodo = "POST";
      }
    },

    async getEstados() {
      await axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getClientes() {
      await axios.get("/api/com/clientes").then((response) => {
        this.listasForms.clientes = response.data.data;
      });
    },

    async refrescarDetFac() {
      this.cargando = true;
      await axios
        .get("/api/com/detGuiaRegalias", {
          params: {
            id: this.form.id,
            accion: "Refresh",
            cliente_id: this.form.arCliente.id,
            fecha_fin: this.form.fecha_fin,
            fecha_ini: this.form.fecha_ini,
          },
        })
        .then((response) => {
          this.listasForms.detFacturacion = response.data;
        });

      await this.agruparViajes();
      this.cargando = false;
    },

    async validarRango() {
      axios
        .get("api/com/facturacion/validarRango", {
          params: {
            fecha_ini: this.form.fecha_ini,
            fecha_fin: this.form.fecha_fin,
            cliente_id: this.form.arCliente.id,
          },
        })
        .then((response) => {
          if (response.data && this.accion == "Crear") {
            this.$swal({
              icon: "error",
              title:
                "Una facturación del cliente en el mismo rango de fechas ya existe...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else if (
            this.accion == "Editar" ||
            (!response.data && this.accion == "Crear")
          ) {
            this.save();
            this.$swal({
              icon: "success",
              title: "Guardando...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    save() {
      this.cargando = true;
      let data = {
        id: this.form.id,
        cliente_id: this.form.arCliente ? this.form.arCliente.id : null,
        fecha_fin: this.form.fecha_fin,
        fecha_ini: this.form.fecha_ini,
        estado: this.form.arEstado ? this.form.arEstado.numeracion : null,
        observaciones: this.form.observaciones,
      };
      axios
        .put("api/com/facturacion", data)
        .then((response) => {
          this.accion = "Editar";
          this.form = {
            id: response.data.facturacion.id,
            fecha_ini: response.data.facturacion.fecha_ini,
            fecha_fin: response.data.facturacion.fecha_fin,
            observaciones: response.data.facturacion.observaciones,
            arEstado: this.listasForms.estados.find(
              (estado) => estado.numeracion === response.data.facturacion.estado
            ),
            arCliente: this.listasForms.clientes.find(
              (cliente) => cliente.id === response.data.facturacion.cliente_id
            ),
          };
          if (this.form.id) {
            this.bloquear = true;
          }
          this.listasForms.detFacturacion = response.data.detFacturacion;
          this.agruparViajes();
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          //this.back();
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });

      this.cargando = false;
    },

    back() {
      return this.$router.replace("/Com/Liquidaciones");
    },
    agruparViajes() {
      if (this.listasForms.detFacturacion[0]) {
        const viajesgroup = this.listasForms.detFacturacion.reduce(
          (acc, viaje) => {
            const sitioOrigenId = viaje.sitio_id;

            if (!acc[sitioOrigenId]) {
              acc[sitioOrigenId] = [];
            }
            acc[sitioOrigenId].push(viaje);
            return acc;
          },
          {}
        );
        this.viajesGrupo = Object.values(viajesgroup);
      } else {
        this.listasForms.detFacturacion = [];
        this.viajesGrupo = [];
      }
    },
    cambioPestaña(index) {
      this.posicion = null;
      this.posicion = index;
    },

    llenarModalDetViaje(viaje, tipo_control) {
      this.$refs.ViajesDetViaje.llenar_modal_detViaje(viaje, tipo_control);
    },
  },
  async mounted() {
    this.cargando = true;
    await this.init();
    this.cargando = false;
  },
};
</script>
